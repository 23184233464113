<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
    },
    methods: {
        onChange(event) {
            this.$emit('input', event.target.value)
        },
    },
}
</script>

<template>
    <div :class="$style.radios">
        <div v-if="field.label" :class="$style.label">
            {{ field.label }}
        </div>
        <div class="radios">
            <div v-for="(radio, key) in field.list" :key="key" :class="$style.field">
                <div :class="$style.fieldWrapper">
                    <div :class="$style.radioInputWrapper">
                        <input
                            :id="field.name + radio.value"
                            :name="field.name"
                            :value="radio.value"
                            :checked="radio.value == value"
                            type="radio"
                            @change="onChange"
                            v-on="listeners"
                        />
                    </div>
                    <label :for="field.name + radio.value" :class="$style.radioLabel">
                        <div :class="$style.radioName">
                            <span>{{ radio.name }}</span>
                            {{ radio.sub }}
                        </div>
                        <span v-if="radio.details" :class="$style.radioDetails">
                            {{ radio.details }}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.field:not(:last-child) {
    margin-bottom: 7px;
}
.fieldWrapper {
    display: flex;
    align-items: center;
    background: $color-background-input;
    border-radius: $border-radius;
}
.radios {
    input {
        position: relative;
        width: 18px;
        height: 18px;
        vertical-align: -4px;
        cursor: pointer;
        border: 1px solid;
        border-color: #d9d9d9;
        border-radius: 50%;
        box-shadow: 0 0 0 0 $color-link inset;
        transition: all 0.2s ease-in-out;
        &:checked + label {
            color: $color-link !important;
        }
        &:checked {
            border: none;
            box-shadow: 0 0 0 10px $color-link inset;
            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 4px;
                height: 4px;
                margin-top: -2px;
                margin-left: -2px;
                content: '';
                background-color: #fff;
                border-radius: 50%;
                opacity: 0;
                opacity: 1;
                transition: all 0.2s ease-in-out 0.1s;
                transform: scale(1);
            }
        }
    }
}
.radioLabel {
    font-size: 0.95em;
    color: $color-text-soft;
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 13px 15px;
}
.radioInputWrapper {
    padding: 13px 0 13px 15px;
}
.radioName {
    flex: 1;
    span {
        display: block;
        color: $color-text;
        font-weight: 600;
    }
}
.radioDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    font-family: $font-family-heading;
    font-weight: 700;
}
.label {
    font-size: 0.95em;
    color: #737373;
}
</style>
